<template>
	<div>
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<div class="row">
				<div class="col-auto ml-auto d-flex">
			        <a href="" class="btn ml-3 btn-secondary" @click.prevent.prevent="quickContractPreview(contract_id)">
			            <i><font-awesome-icon :icon="['fas', 'eye']" /></i> {{ $t('global.appercu') }}
			        </a>
			        <a href="" class="btn ml-3 btn-secondary" @click.prevent.prevent="setupCourrierType(contract_id)">
			            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('courrier_type.generation') }}
			        </a>
			        <a href="" class="btn ml-3 btn-secondary" @click.prevent.prevent="signature" v-if="avenant.status.avenantstatus_id < 2 && contract.contract_num != null">
			            <i><font-awesome-icon :icon="['far', 'signature']" /></i> {{ $t('monte.valider') }}
			        </a>
			        <a href="" class="btn ml-3 btn-secondary" @click.prevent.prevent="signature" v-if="avenant.status.avenantstatus_id == 2">
			            <i><font-awesome-icon :icon="['far', 'signature']" /></i> {{ $t('monte.signer') }}
			        </a>
					
					<a href="" v-if="contract.contract_num != null" class="btn ml-3 btn-secondary" @click.prevent.prevent="setupContractAValiderV2(contract_id, form.jument.horse_id, processing)">
						<i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.etat_facturation') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
					</a>

					<a href="" v-if="avenant.status.avenantstatus_id == 3 || avenant.status.avenantstatus_id == 2" class="btn ml-3 btn-secondary" @click.prevent.prevent="addAvenant">
			            <i><font-awesome-icon :icon="['far', 'plus']" /></i> {{ $t('monte.creer_avenant') }}
			        </a>
			        <a href="" v-if="has_many_avenant" class="btn ml-3 btn-secondary" @click.prevent.prevent="setupViewHistorique(contract_id)">
			            <i><font-awesome-icon :icon="['far', 'history']" /></i> {{ $t('monte.view_history') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
			        </a>
			        <a href="" class="btn ml-3 btn-secondary" @click.prevent.prevent="setupDocument(contract_id)">
			            <i><font-awesome-icon :icon="['fal', 'photo-video']" /></i> {{ $t('monte.documents') }}
						<span v-if="nb_documents && nb_documents > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
							{{ nb_documents }}
						</span>
			        </a>


					<b-dropdown
						lazy
						right
						class="ml-3"
					>
						<template v-slot:button-content>
							<font-awesome-icon v-if="processing_action" pulse :icon="['fas', 'spinner']" />
							<font-awesome-icon v-else :icon="['far', 'cogs']" />
							&nbsp;<span class="d-none d-lg-inline">{{ $t('action.actions') }}</span>
						</template>
						<b-dropdown-item @click.prevent.prevent="callPrintContracts(contract_id)">
							<font-awesome-icon :icon="['far', 'file-pdf']" v-if="spinning_print == false" />
							<font-awesome-icon :icon="['far', 'spinner']" :pulse="true" v-else />
							{{ $t('global.imprimer') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="avenant.status.avenantstatus_id == 3 || avenant.status.avenantstatus_id == 2" @click.prevent.prevent="callCancelContracts(contract_id)">
							<font-awesome-icon :icon="['far', 'times']" />
							{{ $t('action.annuler') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="avenant.status.avenantstatus_id == 3 || avenant.status.avenantstatus_id == 2" @click.prevent.prevent="callUnsignContracts(avenant.avenant_id)">
							<font-awesome-icon :icon="['far', 'times']" />
							{{ $t('action.devalider_designer') }}
						</b-dropdown-item>
						<b-dropdown-item @click.prevent.prevent="callSendContracts(contract_id)">
							<font-awesome-icon :icon="['far', 'paper-plane']" v-if="spinning_send == false" />
							<font-awesome-icon :icon="['far', 'spinner']" :pulse="true" v-else />
							{{ $t('action.envoyer') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="avenant.status.avenantstatus_id == 4" @click.prevent.prevent="callUndoCancelContracts(contract_id)">
							<font-awesome-icon :icon="['far', 'times']" />
							{{ $t('action.desannuler') }}
						</b-dropdown-item>
						<b-dropdown-item @click.prevent.prevent="goToSaveContractVersion">
							<font-awesome-icon :icon="['far', 'save']" />
							{{ $t('global.sauvegarder') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="hasSignatureAccess && contract.contract_num != null" @click.prevent.prevent="openModalSignature">
							<font-awesome-icon :icon="['far', 'signature']" />
							{{ $t('action.signature') }}
						</b-dropdown-item>
					</b-dropdown>

					<b-dropdown
						lazy
						right
						v-if="avenant.status.avenantstatus_id == 3 || avenant.status.avenantstatus_id == 2"
						class="ml-3"
					>
						<template v-slot:button-content>
							<font-awesome-icon :icon="['far', 'horse']" />
							&nbsp;<span class="d-none d-lg-inline">{{ $t('monte.actes') }}</span>
						</template>
						<b-dropdown-item @click.prevent.prevent="openPoulinage">{{ $t('monte.ajouter_poulinage') }}</b-dropdown-item>
						<b-dropdown-item @click.prevent.prevent="openDG">{{ $t('monte.ajouter_dg') }}</b-dropdown-item>
						<b-dropdown-item @click.prevent.prevent="openSaillie">{{ $t('monte.ajouter_saillie') }}</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
			<form @submit.prevent="checkForm">
				<div class="row">
					<div class="col-xl-6">
						<div class="row mt-3" v-if="avenant.status">
							<label for="avenant_status" class="col-sm-4 col-form-label">{{ $t('monte.status_contract') }}</label>
							<div class="col-sm-8"><input type="text" class="form-control" readonly v-model="avenant.status.avenantstatus_label"></div>
						</div>
						<div class="row mt-3" v-if="this.contract.contract_num">
							<label for="contract_num" class="col-sm-4 col-form-label">{{ $t('monte.numero_contrat') }}</label>
							<div class="col-sm-8"><input type="text" class="form-control" readonly v-model="this.contract.contract_num"></div>
						</div>
						<div class="row mt-3">
							<label for="season" class="col-sm-4 col-form-label">
								<div class="row">
									<div class="col-12">
										{{ $t('tiers.tiers') }} *
										<span class="float-right">
											<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: form.tiers.tiers_id }}">
												<font-awesome-icon :icon="['fal', 'link']" />
											</router-link>
										</span>
									</div>
									<div class="col-12" v-if="form.tiers && form.tiers.tiers_id">
										<TagsManagement :fk="form.tiers.tiers_id" type="tiers" />
									</div>
								</div>
							</label>
							<div class="col-sm-8">
								<!-- <e-select
									:disabled="isDisabled"
									v-model="form.tiers"
									track-by="tiers_id"
									label="tiers_formatted"
									:placeholder="labelTitleTiers"
									:selectedLabel="selectedLabelTiers"
									:options="tiers"
									:allow-empty="false"
									:show-labels="false"
									:loading="loadingTiers"
									:class="{ 'is-invalid': errors && errors.indexOf('tiers') > -1 }"
								></e-select> -->
								<SearchTiers 
									:tiers_selected.sync="form.tiers"
									:preselected="form.tiers"
									:tiers="tiers_options"
								/>
							</div>
							<div class="col" v-if="tiers_contract_error">
								<b-alert variant="danger" show>
									{{ $t('monte.tiers_contract_zero') }}
								</b-alert>
							</div>
						</div>

						<template v-if="Object.keys(form.tiers).length > 0 && contract.contract_id">
							<div class="row mt-3">
								<label for="season" class="col-sm-4 col-form-label">
									<div class="row">
										<div class="col-12">
											{{ $t('monte.jument') }}
											<span class="float-right" v-if="form.jument.horse_id">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: form.jument.horse_id }}">
													<font-awesome-icon :icon="['fal', 'link']" />
												</router-link>
											</span>
										</div>
										<div class="col-12" v-if="form.jument && form.jument.horse_id">
											<TagsManagement :fk="form.jument.horse_id" type="horse" />
										</div>
									</div>
								</label>
								<div class="col-sm-8">
									<!-- <e-select
										v-model="form.jument"
										track-by="horse_id"
										label="horse_nom"
										:placeholder="labelTitleJument"
										:selectedLabel="selectedLabelJument"
										:options="juments"
										:allow-empty="false"
										:show-labels="false"
										:loading="loadingJument"
										:disabled="isDisabled"
										:class="{ 'is-invalid': errors && errors.indexOf('jument') > -1 }"
									></e-select> -->
									<div class="row">
										<div class="col-sm-12 col-lg-6">
											<SearchJument
												ref="searchJument"
												:horse_selected.sync="form.jument"
												:preselected="form.jument"
												:addHorse="true"
												:tier="this.form.tier"
												:juments="juments_options"
												@addHorseVal="openAddHorseModal"
												@stopFocus="stopFocus()"
											/>
										</div>
										<div class="col-sm-12 col-lg-6" v-if="jumentInfo">
											<span>
												{{ $t('horse.numero_sire') }}: <e-copybox :value="jumentInfo.sire"></e-copybox><br>
												{{ $t('horse.pere') }}: {{jumentInfo.pere}}
											</span>
										</div>
									</div>
									<div v-if="couple_contract" class="mt-1 mb-0 alert alert-warning">{{ $t('monte.couple_alreay_contract') }}</div>
									<div v-if="!couple_contract && mare_contract" class="mt-1 mb-0 alert alert-warning">{{ $t('monte.mare_alreay_contract') }}</div>
								</div>
								<div v-if="jument && jument.horse_mort === 1">
									<b-alert show variant="warning" class="mt-2">
										<font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
										{{ $t('horse.Jument_morte') }}
									</b-alert>
								</div>
							</div>
							<div class="row mt-3">
								<div class="col-sm-12">
									<b-form-checkbox
									id="avenant_revue"
									v-model="avenant.avenant_revue_bool"
									:value="1"
									:unchecked-value="0"
									:disabled="isDisabled"
									>
									{{ $t("monte.avenant_revue") }}
									</b-form-checkbox>
								</div>
							</div>
							<div v-if="jument" v-show="jumentInfo && jumentInfo.sire" class="row mt-3">
								<a href="" class="ml-3" @click.prevent='loadPreviousDPSinfo'>
									<font-awesome-icon v-if="processing_previous_dps" :icon="['fas', 'spinner']" pulse />
									{{ $t('gynecologie.titre_previousdps_saillie_ws') }}								
								</a>
							</div>
							<div v-if="form.jument" class="row mt-3">
								<label class="col-sm-4 col-form-label">{{ $t('gynecologie.mare_status') }}</label>
								<div class="col-sm-8">
									<e-select
										v-model="mare_status_selected"
										id="marestatus_id"
										track-by="marestatus_id"
										:placeholder="$t('gynecologie.selectionnez_etat')"
										:selectedLabel="$t('global.selected_label')"
										:options="mare_status"
										:searchable="false"
										:allow-empty="false"
										:show-labels="false"
									>
										<template slot="option" slot-scope="{ option }">{{ $t('gynecologie.'+option.marestatus_code) }}</template>
										<template slot="singleLabel" slot-scope="{ option }">{{ $t('gynecologie.'+option.marestatus_code) }}</template>
										<template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</div>
							</div>

							<Saison
								ref="Saison"
								:all_season="all_season"
								:season="contract.contract_config.season"
								v-model="season"
								:disabled="isDisabled"
							>
							</Saison>

							<Etalonv2
								ref="Etalon"
								:stallion_id="contract_config.contractconfig_horse"
								v-model="stallion"
								:contractconfig_id.sync="contractconfig_id"
								:contract_config.sync="contract_config"
								:season.sync="season"
								:disabled="isDisabled"
								:nb_vs="nb_vs"
								:nb_p="nb_p"
							>
							</Etalonv2>

							<Modelev2
								ref="Modele"
								:contractconfig_id="contractconfig_id"
								:avenant_model.sync="avenant.avenant_model"
								:stallion_models="stallion_models"
								v-model="modele"
							></Modelev2>
							<!-- :disabled="isDisabled" -->

							<TypeMontev2
								ref="TypeMonte"
								:contractconfig_id="contractconfig_id"
								:avenant_typemonte="avenant.avenant_typemonte"
								:type_montes_stallion="type_montes_stallion"
								v-model="type_monte"
								:disabled="isDisabled"
								:tiers_country="form.tiers.tiers_country"
							></TypeMontev2>

							<div class="row mt-3">
								<div class="col-sm-4 col-form-label"></div>
								<div class="col-sm-8">
									<b-form-checkbox
										v-model="form.avenant_transfert"
										:value="1"
										:unchecked-value="0"
									>
										{{ $t('monte.transfert') }}
									</b-form-checkbox>
								</div>
							</div>

							<div class="row mt-3" v-if="form.avenant_transfert && form.jument.horse_id">
								<label for="porteuse" class="col-sm-4 col-form-label">
									<div class="row">
										<div class="col-12">
											{{ $t('monte.porteuse') }}
											<span class="float-right" v-if="form.porteuse">
												<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: form.porteuse.horse_id }}">
													<font-awesome-icon :icon="['fal', 'link']" />
												</router-link>
											</span>
										</div>
										<div class="col-12" v-if="form.porteuse && form.porteuse.horse_id">
											<TagsManagement :fk="form.porteuse.horse_id" type="horse" />
										</div>
									</div>
								</label>
								<div class="col-sm-7">
									<!-- <e-select
										v-model="form.porteuse"
										track-by="horse_id"
										label="horse_nom"
										:placeholder="labelTitlePorteuse"
										:selectedLabel="selectedLabelPorteuse"
										:options="juments_porteuse"
										:allow-empty="false"
										:show-labels="false"
										:loading="loadingJument"
										:class="{ 'is-invalid': errors && errors.indexOf('jument') > -1 }"
									></e-select> -->
									<SearchJument
										:horse_selected.sync="form.porteuse"
										:preselected="form.porteuse"
									/>
								</div>
								<div class="col-sm-1">
				                	<button class="btn btn-secondary" @click.prevent="addPorteuse"><font-awesome-icon :icon="['far', 'plus']" /></button>
				                </div>
							</div>

							<div v-if="most_used_cmep" class="row mt-3">
								<i class="btn btn-link my-auto" @click.prevent="loadCmep()">{{ $t("tiers.cmep_want_to") }} {{ most_used_cmep.tiers_rs }} ?</i>
							</div>
							<ChangementCMEPv2
								ref="changementCMEP"
								:contract_id="contract_id"
								:avenant.sync="avenant"
								:tiers_tab.sync="all_tiers"
								:cmep.sync="form.cmep"
								:disabled="disabledCmep"
							>
							</ChangementCMEPv2>
							<div class="row mt-3">
								<label class="col-sm-4 col-form-label" for="courtier">
									<div class="row">
										<div class="col-12">
											{{ $t('monte.courtier') }}
											<span class="float-right" v-if="form.courtier && form.courtier.tiers_id">
												<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: form.courtier.tiers_id }}">
													<font-awesome-icon :icon="['fal', 'link']" />
												</router-link>
											</span>
										</div>
										<div class="col-12" v-if="form.courtier && form.courtier.tiers_id">
											<TagsManagement :fk="form.courtier.tiers_id" type="tiers" />
										</div>
									</div>
								</label>
								<div class="col-sm-8">
									<!-- <e-select
										v-model="form.courtier"
										track-by="tiers_id"
										label="tiers_rs"
										:placeholder="labelTitleCourtier"
										:selectedLabel="selectedLabelCourtier"
										:options="tiers_courtier"
										:allow-empty="true"
										:show-labels="false"
										:loading="loadingTiers"
										:class="{ 'is-invalid': errors && errors.indexOf('courtier') > -1 }"
									></e-select> -->

									<SearchTiers 
										:tiers_selected.sync="form.courtier"
										:preselected="form.courtier"
									/>
								</div>
							</div>
							<div class="row mt-3" v-if="avenant.avenant_type_contrat != 'etalonnier' && syndic_saillie_formatted.length > 0">
								<label for="saillie" class="col-sm-4 col-form-label">{{ $t('monte.num_parts_saillie') }}</label>
								<div class="col-sm-8">
									<e-select
										v-model="saillie"
										id="saillie"
										label="value"
										track-by="saillie_id"
										:placeholder="$t('monte.numero_saillie')"
										:selectedLabel="$t('global.selected_label')"
										:options="syndic_saillie_formatted"
										:allow-empty="false"
										:show-labels="false"
										:loading="load_syndic"
										:class="{ 'is-invalid': errors && errors.indexOf('saillie') > -1 }"
									>
										<template slot="option" slot-scope="{ option }">{{ option.value }}</template>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.value }}</template>
									</e-select>
									<b-form-checkbox
										v-if="saillie"
										id="free_saillie"
										v-model="free_saillie"
										:value="1"
									>
										{{ $t("monte.free_saillie") }}
									</b-form-checkbox>
								</div>
							</div>
							<template v-if="avenant.status.avenantstatus_id == 1 || avenant.status.avenantstatus_id == 2 || avenant.status.avenantstatus_id == 3">
								<div v-if="avenant.avenant_date_signature" class="row mt-3">
									<label for="date_signature" class="col-sm-4 col-form-label">{{ $t("monte.signature_date") }}</label>
									<div class="col-sm-8">
										<input class="form-control" v-model="date_signature" id="date_signature" disabled />
									</div>
								</div>
								<div class="row mt-3" v-if="syndic_saillie && syndic_saillie.length > 0">
									<label for="type_contrat" class="col-sm-4 col-form-label">{{ $t("monte.type_contrat") }}</label>
									<div class="col-sm-8">
										<input type="text" class="form-control" :value="avenant.avenant_type_contrat?$t('monte.'+avenant.avenant_type_contrat):''" id="type_contrat" disabled />
									</div>
								</div>


								<div class="row mt-3" v-show="jumentInfo && jumentInfo.sire">
									<label class="col-sm-4 col-form-label" for="numero_dps">{{ $t("monte.numero_dps") }}</label>
									<div class="col-sm-8">
										<input type="text" class="form-control" v-model="num_dps" id="numero_dps" >
									</div>
								</div>
								<div v-if="contract && !num_dps && avenant.avenant_horse" v-show="jumentInfo && jumentInfo.sire" class="row">
									<div class="col-sm-4"></div>
									<div class="col-sm-8">
										<a href="#" @click.prevent="loadDPSinfi">{{ $t('monte.interroger_dps') }} <font-awesome-icon v-if="processing_dps" :icon="['far', 'spinner']" :pulse="true" /></a>
									</div>
								</div>
								<div class="row mt-3">
									<label class="col-sm-4 col-form-label" for="paillette">{{ $t("monte.nombre_paillettes") }}</label>
									<div class="col-sm-8">
										<input type="text" class="form-control" v-model="form.paillette" id="paillette" >
									</div>
								</div>
								<div class="row mt-3">
									<label class="col-sm-4 col-form-label" for="contract_created">{{ $t("monte.contract_created") }}</label>
									<div class="col-sm-8">
										<e-datepicker v-model="form.contract_created" id="contract_created" ></e-datepicker>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-sm-12">
										<b-form-checkbox
										id="attestation_saillie"
										v-model="avenant.avenant_as_bool"
										:value="1"
										:unchecked-value="0"
										>
										{{ $t("monte.attestation_saillie") }}
										</b-form-checkbox>
									</div>
								</div>
								<div class="row mt-3" v-if="avenant.avenant_as_bool">
									<div class="col-sm-4">
										<b-form-checkbox
											v-if="hasClientArea"
											v-model="document_as_ca"
											:unchecked-value="0"
										>
											{{ $t("monte.available_ca") }}
										</b-form-checkbox>
										<a href="#" @click.prevent.prevent="shareDocumentAS">{{ $t('monte.partager_document_as') }}</a>
									</div>
									<div class="col-sm-8">
										<b-form-file
								            v-model="document_as"
								            accept="image/jpeg, image/jpg, image/png, application/pdf"
								            :state="Boolean(document_as)"
								            :placeholder="$t('invoice.devis.choisir_document')"
								            :drop-placeholder="$t('fichier.drop_files')+'...'"
								        ></b-form-file>
								        <div v-show="document_as && !infos_contract[0].contract_document_as.contractdocument_id" class="mt-2">
								            {{ $t('fichier.selected_files') }} :
								            <span> {{ document_as ? document_as.name : '' }}</span>
								        </div>
										<div v-show="infos_contract[0].contract_document_as.contractdocument_id">
											{{ $t('fichier.registered_files') }} :
											<a href="" class="" @click.prevent.prevent="quickPreview(infos_contract[0].contract_document_as)">
												{{ infos_contract[0].contract_document_as.contractdocument_label }}
											</a>
											<a href="" class="" @click.prevent.prevent="deleteDocument(infos_contract[0].contract_document_as)"> <font-awesome-icon :icon="['fal', 'trash-alt']"/> </a>
										</div>
									</div>
								</div>



								<div class="row mt-3">
									<div class="col-sm-4">
										<b-form-checkbox
										id="declaration_naissance"
										v-model="avenant.avenant_dn_bool"
										:value="1"
										:unchecked-value="0"
										>
										{{ $t("monte.declaration_naissance") }}
										</b-form-checkbox>
									</div>
									<div class="col-sm-8">
										<e-datepicker v-if="avenant.avenant_dn_bool" v-model="avenant.avenant_dn" id="avenant_dn" ></e-datepicker>
									</div>
								</div>
								<div class="row mt-3" v-if="avenant.avenant_dn_bool">
									<div class="col-sm-4">
										<b-form-checkbox
											v-if="hasClientArea"
											v-model="document_dn_ca"
											:unchecked-value="0"
										>
											{{ $t("monte.available_ca") }}
										</b-form-checkbox>
										<a href="#" @click.prevent.prevent="shareDocumentDN">{{ $t('monte.partager_document_dn') }}</a>
									</div>
									<div class="col-sm-8">
										<b-form-file
								            v-model="document_dn"
								            accept="image/jpeg, image/jpg, image/png, application/pdf"
								            :state="Boolean(document_dn)"
								            :placeholder="$t('invoice.devis.choisir_document')"
								            :drop-placeholder="$t('fichier.drop_files')+'...'"
								        ></b-form-file>
								        <div v-show="document_dn && !infos_contract[0].contract_document_dn.contractdocument_id" class="mt-2">
								            {{ $t('fichier.selected_files') }} :
								            <span> {{ document_dn ? document_dn.name : '' }}</span>
								        </div>
										<div v-show="infos_contract[0].contract_document_dn.contractdocument_id">
											{{ $t('fichier.registered_files') }} :
											<a href="" class="" @click.prevent.prevent="quickPreview(infos_contract[0].contract_document_dn)">
												{{ infos_contract[0].contract_document_dn.contractdocument_label }}
											</a>
											<a href="" class="" @click.prevent.prevent="deleteDocument(infos_contract[0].contract_document_dn)"> <font-awesome-icon :icon="['fal', 'trash-alt']"/> </a>
										</div>
										
									</div>
								</div>
								<div class="row mt-3">
									<a href="#" @click.prevent.prevent="shareDocumentsDNAndAS">{{ $t('monte.partager_document_dn_and_as') }}</a>
								</div>


							</template>

						</template>
					</div>
					<div class="col-xl-6 mt-3">
						<template v-if="Object.keys(form.tiers).length > 0 || contract.contract_id">

								<div class="form-group">
									<label for="season">{{ $t('monte.avenant_justification') }}</label>
									<e-editor-froala
										identifier="avenant_justification"
										:placeholder="getTrad('monte.avenant_justification')"
										:content="justification"
										@change="updateEditorJustification"
										:disabled="isDisabled"
										:moreText="[ 'bold', 'italic', 'strikeThrough', 'underline', 'undo', 'redo', 'formatUL', 'formatOL', 'clearFormatting' ]"
									></e-editor-froala>
								</div>
								<div class="form-group">
									<label for="season">{{ $t('monte.avenant_comment') }}</label>

									<e-editor-froala
										identifier="avenant_comment"
										:placeholder="getTrad('monte.avenant_comment')"
										:content="comment"
										@change="updateEditorComment"
										:moreText="[ 'bold', 'italic', 'strikeThrough', 'underline', 'undo', 'redo', 'formatUL', 'formatOL', 'clearFormatting' ]"
									></e-editor-froala>
								</div>
								<div class="form-group">
									<label for="season">{{ $t('monte.contract_note') }}</label>
									<e-editor-froala
										identifier="contract_note"
										:placeholder="getTrad('monte.contract_note')"
										:content="note"
										@change="updateEditorNote"
										:moreText="[ 'bold', 'italic', 'strikeThrough', 'underline', 'undo', 'redo', 'formatUL', 'formatOL', 'clearFormatting' ]"
									></e-editor-froala>
								</div>
								<div class="form-group" v-if="contract.seasonmarestallion">
									<span>{{ contract.seasonmarestallion_label }}: <span :class="contract.seasonmarestallion.customClasses.seasonmarestallion_status">{{ seasonmarestallion_status }}</span></span>
								</div>
								<div v-if="tracking">
									<span>{{ $t('monte.date_envoi') }} : {{ tracking }}</span>
								</div>
								<div v-if="tracking_signature">
									<span>{{ $t('monte.date_envoi_signature') }} : {{ tracking_signature.date }} ({{ tracking_signature.user }})</span>
								</div>
						</template>
					</div>
				</div>
					<template v-if="Object.keys(form.tiers).length > 0">
						<div class="row">
							<h2 class="my-3 col-12">{{ $t('monte.facturation') }}</h2>
						</div>
						<div class="row">
							<div class="col-sm-4 col-form-label"></div>
							<div class="col-sm-auto">
								<b-form-checkbox
									id="avenant_free"
									v-model="avenant.avenant_free"
									:value="1"
									:unchecked-value="0"
									:disabled="isDisabled || articles_invoice"
								>
									{{ $t("monte.contrat_gratuit") }}
								</b-form-checkbox>
							</div>
						</div>

						<TableAvenantArticlev2
							ref="TableAvenantArticle"
							:has_condition_suggestion="tableAvenantShow"
							:articles="infos_contract[0].articles"
							:tiers_tab="all_tiers"
							:tiers="form.tiers"
							:typemonte="type_monte"
							:contractconfig_id="contractconfig_id"
							:avenant="avenant"
							:avenant_article.sync="avenant_article"
							:disabled="isDisabled"
							:created_horse_id.sync="form.jument.horse_id"
							:display_action_button="true"
							:free="avenant.avenant_free"
							:contract_id="contract_id"
							:is_ready.sync="isTableAvenantArticleReady"
							:launch_reload_condition="true"
							:cc_articles="cc_articles"
							@etatFacturation="loadEtatFacturation"
						></TableAvenantArticlev2>

						<div class="row">
							<div class="col-12" v-if="form_message !== ''">
								<ErrorAlert :messageI18n="form_message" />
							</div>

							<div class="col-12">
								<div class="form-group text-center">
									<button
										class="btn btn-primary rounded-pill"
										@click.prevent='checkContract0'
										:disabled="!isTableAvenantArticleReady"
									>
										<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
										<font-awesome-icon v-else :icon="['fal', 'save']" class="ml-1" />
										{{$t("global.enregistrer")}}
									</button>

									<button
										v-if="avenant.status.avenantstatus_id < 2"
										class="btn btn-secondary rounded-pill ml-2"
										@click.prevent='saveInfoValide'
										:disabled="!isTableAvenantArticleReady"
									>
										<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
										<font-awesome-icon v-else :icon="['fal', 'save']" class="ml-1" />
										{{$t("global.enregistrer_valider")}}
									</button>
								</div>
							</div>
						</div>
					</template>



				<div class="row mt-3">
					<div class="col-12">
						<div class="form-group" v-if="canTakeResaMonte">
							<div class="">
								<ReservationMonte
									:contract_id="contract_id"
									:available_tiers="all_tiers"
									:available_mares="all_juments"
									:available_centre_stockage="available_centre_stockage"
									:season="season"
									:stallion="stallion"
									:reservations="reservations"
									:mare.sync="form.jument"
									:cmep.sync="form.cmep"
								/>
							</div>
						</div>
					</div>
				</div>


			</form>

			<b-modal ref="modalAddHorse" hide-footer size="lg">
                <template v-slot:modal-title>
                        {{ $t("tiers.ajouter_tiers_horse") }}
                </template>

                <HorseAjout 
                    :add-or-edit-ready.sync="addHorseReady"
                    :disable-button.sync="disableButton"
                    ref="tiersHorseAjout"
                    :tiershorse_id="null"
                    :tiers="form.tiers"
                    :tiers_horse="[]"
					:horseToAddName.sync="horseToAddName"
					@horse_id="assignHorse"
					:horseSexeMandatory =horseSexeMandatory
                />

                <b-button class="mt-3 btn-block rounded-pill" block @click.prevent.prevent="checkFormHorse" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
            </b-modal>

			<ModalAddAvenantv2
				ref="modalAddAvenant"
				:stallion_id="contract.contract_config.contractconfig_horse"
				:season_id="contract.contract_config.contractconfig_season"
				:season_choosen="season"
				:contractconfig_id="contractconfig_id"
				:avenant_typemonte="avenant.avenant_typemonte"
				:tiers_tab="all_tiers"
				:tiers="form.tiers"
				:typemonte="type_monte"
				:avenant="avenant"
				:horse="form.jument"
				:is_cancel="isAvenantCancel"
				:all_season="all_season"
				:syndic_saillie="syndic_saillie"
				:contract_id="contract_id"
				@avenant_saved="init_component(false)"
			>
			</ModalAddAvenantv2>

			<ModalSignaturev2
				v-if="contract.contract_config && (contract.contract_config.contractconfig_id || contract_config.contractconfig_id)  && season.season_id"
				ref="modalContractSignature"
				:avenant="avenant"
				:contract_id="contract_id"
				:tiers_tab="all_tiers"
				:tiers="form.tiers"
				:type_monte="type_monte"
				:contractconfig_id="contractconfig_id"
				:contract_config="contract.config || contract_config"
				:stallion="stallion"
				:season_id="season.season_id"
				:syndic_saillie="syndic_saillie"
				:load_syndic="load_syndic"
				:num_dps="num_dps"
				:form="form"
				@contract_signed="contractSigned"
			>
			</ModalSignaturev2>

			<b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
				<template v-slot:modal-title>
					{{ $t("action.previsualiser") }}
				</template>

				<iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
				<div v-else>
					<LoadingSpinner />
				</div>
			</b-modal>

			<b-modal ref="modalConfirm0" hide-footer @hidden="base64 = null">
				<template v-slot:modal-title>
					{{ $t("action.confirmation") }}
				</template>
				
				<h3>{{ $t("monte.ttc_0_confirmation_1") }} {{ this.actual_price_and_currency }}</h3> 
				<h3>{{ $t("monte.ttc_0_confirmation_2") }}</h3>
				<b-button class="mt-3 rounded-pill" block @click.prevent.prevent="continueContract0" variant="primary">{{ $t("global.continuer") }}</b-button>
				<b-button class="mt-3 rounded-pill" block @click.prevent.prevent="cancelContract0" variant="primary">{{ $t("global.annuler") }}</b-button>

			</b-modal>

			<b-modal size="xl" ref="modelQuickPreview" hide-footer @hidden="preview = null">
	            <template v-slot:modal-title>
	                {{ $t("action.previsualiser") }}
	            </template>

	            <iframe v-if="preview != null" :src="preview" height="1000px" width="100%" style="position:relative"></iframe>
	        </b-modal>

			<b-modal size="xl" ref="modalPreviousDPS" hide-footer>
				<template v-slot:modal-title>
					{{ $t('gynecologie.titre_dps_saillie_ws') }}
				</template>

				<div>
					<template>
						<div v-if="error_dps" class="alert alert-warning">
							{{ $t('gynecologie.erreur_dps_saillie_ws') }}
						</div>
						
						<WarnAlert messageI18n="monte.dps_creation_acte" />

						<div class="box my-3" v-for="data in previousDPS" v-bind:key="data.monteSaillie">
							<div class="text-right mb-1">
								<b-form-checkbox v-model="data.selected"></b-form-checkbox>
							</div>
							{{ $t('horse.etalon') }} : <b>{{ data.etalonNom }}</b>
							<br>
							{{ $t('monte.porteuse') }} : <b>{{ data.porteuseNom }}</b>
							<br>
							{{ $t('monte.date_premier_saut') }} : <b>{{ data.monteDatePremierSaut_format }}</b>
							<br>
							{{ $t('compte.datenaissance') }} : <b>{{ data.produitDateNaiss_format }}</b>
							<br>
							{{ $t('horse.nom_naissance') }} : <b>{{ data.produitNom }}</b>
							<br>
							{{ $t('horse.nom_retenue') }} : <b>{{ data.nomRetenu }}</b>

							<e-select
								v-if="show_dps"
								v-model="data.acte_type"
								id="actestype_id"
								track-by="actestype_id"
								label="actestype_label"
								:placeholder="$t('acte.selectionnez_acte')"
								:selectedLabel="$t('global.selected_label')"
								:options="actes_types"
								:searchable="true"
								:allow-empty="false"
								:show-labels="false"
								:group-select="false"
								@input="updateDPS"
							>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
		
						<div class="text-center mt-3">
							<b-button variant="primary" @click="add_dps()">
								<font-awesome-icon v-if="loading_add_dps" :icon="['fas', 'spinner']" pulse />
								{{ $t('global.ajouter') }}
							</b-button>
						</div>
					</template>
				</div>
			</b-modal>

			<ModalSendContract ref="modal_send_contract"/>
			<ModalSignatureContract :contract="contract" ref="modal_signature_contract" />
		</div>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import ContractMixin from "@/mixins/Contract.js"
	import GynecologieMixin from "@/mixins/Gynecologie.js"
	import ModelMixin from "@/mixins/Model.js"
	import TiersMixin from "@/mixins/Tiers.js"
	import AccountingMixin from "@/mixins/Accounting.js"
	import AccountingAccountMixin from "@/mixins/AccountingAccount.js"
	import ActeTypeMixin from "@/mixins/ActeType.js"
	import ArticleMixin from "@/mixins/Article.js"
	import HorseMixin from "@/mixins/Horse.js"
	import Syndic from "@/mixins/Syndic.js"
	import _isEmpty from 'lodash/isEmpty'
	import _uniq from 'lodash/uniq'
	import MonteReservationOrder from "@/mixins/MonteReservationOrder.js"
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import HorseAjout from "@/components/Tiers/HorseAjout"
	import Common from '@/assets/js/common.js'


	import ShutterContract from "@/mixins/shutters-manager/Contract.js"
	import Shutter from '@/mixins/Shutter.js'

	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'

	export default {
		name: "ContractFormv2",
		props: {
			contract_id: {
				type: Number,
				default: () => ( 0 )
			},
			from: {
				type: String,
				default: () => ( "" )
			},
			stallion_id: {
				type: Number,
				default: () => ( 0 )
			},
			mare_id: {
				type: Number,
				default: () => ( 0 )
			},
			tier_id: {
				type: Number,
				default: () => ( 0 )
			}
		},
		mixins: [Navigation, Tools, ContractMixin, GynecologieMixin, ModelMixin, TiersMixin, AccountingMixin, AccountingAccountMixin, ArticleMixin, Shutter, ShutterContract, HorseMixin, Syndic, MonteReservationOrder, MonteShutters, ActeTypeMixin],
		data () {
			return {
				horseSexeMandatory: ['F'],
				NewjumentLoaded: false,
				jumentInfo:null,
				form_message: "",
				addOrEditReady: false,
				isDisabled: true,
				loadingTiers: false,
				loadingStallion: false,
				loadingModele: false,
				loadingTypeMonte: false,
				loadingJument: false,
				isTableAvenantArticleReady: false,
				has_part: false,
				preview: null,
				conditions_spe: false,
				table_busy: false,
				saillie: null,
				syndic_saillie: null,
				contractconfig_id: 0,
				processing: false,
				errors: [],
				conditions: [],
				stallions: [],
				all_juments: [],
				juments: [],
				jument: null,
				juments_porteuse: [],
				articles: [],
				tiers: [],
				tiers_courtier: [],
				all_tiers: [],
				entities: [],
				contract_config: {}, //Contract config selectionné en fonction de l'étalon et de la saison
				contract: {}, //Contract
				actual_price_and_currency: null,
				avenant: {}, //Avenant utilisé
				num_part: null,
				num_dps: null,
				previousDPS: null,
				reservations: null,
				disabledCmep: false,
				horseToAddName: null,
				addHorseReady: false,
				disableButton: true,
				tiers_options: [],
				juments_options: [],
				actes_types: [],
				base64: null,
				tableAvenantShow: true,

				form: {
					tiers:  {},
					season:  {},
					stallion:  {},
					modele:  null,
					type_monte:  null,
					jument: {},
					porteuse: {},
					cmep: null,
					courtier: null,
					avenant_comment: '',
					avenant_justification: '',
					avenant_transfert: 0,
					contract_note: '',
					paillette: 0,
					contract_created: new Date()
				},
				labelTitleTiers: this.getTrad("monte.rechercher_tiers"),
				selectedLabelTiers: this.getTrad("global.selected_label"),
				labelTitleJument: this.getTrad("monte.rechercher_jument"),
				selectedLabelJument: this.getTrad("global.selected_label"),
				labelTitleCmep: this.getTrad("monte.rechercher_cmep"),
				selectedLabelCmep: this.getTrad("global.selected_label"),
				labelTitleCourtier: this.getTrad("monte.rechercher_courtier"),
				selectedLabelCourtier: this.getTrad("global.selected_label"),
				labelTitlePorteuse: this.getTrad("monte.rechercher_porteuse"),
				selectedLabelPorteuse: this.getTrad("global.selected_label"),
				extern_slot_columns: [
				    'avenantarticles_ht',
				    'tiers.tiers_rs',
				    'author.tiers_rs',
				    'avenantarticles_invoiceable_date',
				],

				/*NEW */
				season: {},
				stallion: {},
				modele: {},
				type_monte: {},
				avenant_article: {},
				show_bons: false,

				spinning_print: false,
				spinning_send: false,
				has_many_avenant: false,
				note: null,
				comment: null,
				justification: null,
				isAvenantCancel: false,
				saveAndValidate: false,

				mare_status_selected: null,
				mare_status: [],
				season_mare: null,
				mare_contract: false,
				couple_contract: false,
				available_centre_stockage: [],
				all_season: [],
				events_tab: {
					'ContractForm::ReloadContract': this.initContract
				},
				porteuse_created: false,
				syndic_horse: {
					stallion: null,
					season: null
				},
				free_saillie: 0,
				load_syndic: false,
				infos_contract: null,
				most_used_cmep: null,
				articles_invoice: false,
				processing_action: false,
				nb_documents: 0,
				document_dn: null,
				document_dn_ca: false,
				document_as: null,
				document_as_ca: false,
				processing_dps: false,
				loading_add_dps: false,
				error_dps: false,
				processing_previous_dps: false,
				tiers_contract_error: false,
				nb_vs: 0,
				nb_p: 0,
				stallion_models: [],
				type_montes_stallion: [],
				cc_articles: [],
				cc_global: null,
				seasonmarestallion_status: null,
				tracking: null,
				tracking_signature: null,
				show_dps: true
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component(validation = false) {
				this.addOrEditReady = false
				this.syndic_horse = {
					stallion: null,
					season: null
				}

				await this.initContract()

				await this.initForm()

				this.addOrEditReady = true

				this.initTiers()
				this.initSeason()
				this.initMareStatus()

				if(validation === true) {
					this.$nextTick(() => {
						this.signature()
	                })
				}
			},
			async initContract() {
				this.infos_contract = await this.getContractOnline(this.contract_id)
				this.contract = this.infos_contract[0].contract
				this.reservations = this.infos_contract[0].reservations
				this.nb_documents = this.infos_contract[0].nb_documents
				this.num_dps = null
				// this.avenant_article = this.infos_contract[0].articles
				if(this.$refs.TableAvenantArticle) {
					this.$refs.TableAvenantArticle.reloadCondition()
				}

				if(!this.contract.contract_contractconfig) {
					this.contractconfig_id = 0
					this.contract.contract_config = {}
					this.contract.contract_config.contractconfig_horse = 0
				}

				if(!this.contract.contract_config.contractconfig_horse && this.contract_id && (this.$route.params.stallion_id || this.stallion_id)) {
					this.contract.contract_config.contractconfig_horse = this.$route.params.stallion_id ? parseInt(this.$route.params.stallion_id) : parseInt(this.stallion_id)
				}

				this.infos_contract[0].articles.forEach(article => {
					if(article.avenantarticles_invoiceable) {
						this.articles_invoice = true
					}
				})

				// let tries = 5

				// if(window.navigator.onLine && this.contract.contract_id > 0 && this.contractconfig_id !== 0) {
				// 	while(!this.contract.contract_num && tries != 0) {
				// 		await this.$sync.force(true, true)
				// 		this.contract = await this.getContract(this.contract_id)
				// 		tries--
				// 	}
				// }

				this.avenant = this.infos_contract[0].avenant
				if(this.avenant) {
					this.num_dps = this.avenant.avenant_dps
					if(this.avenant.avenant_dn) {
						this.avenant.avenant_dn = Date.parseTz(this.avenant.avenant_dn)
					}
				}
				if(!this.num_dps && this.contract.seasonmarestallion) {
					this.num_dps = this.contract.seasonmarestallion.seasonmarestallion_num_dps
				}

				if(this.infos_contract[0].contract_document_dn && this.infos_contract[0].contract_document_dn.contractdocument_id) {
					this.document_dn = new File([], this.infos_contract[0].contract_document_dn.contractdocument_filename)
					this.document_dn_ca = this.infos_contract[0].contract_document_dn.contractdocument_ca == 1
				}

				if(this.infos_contract[0].contract_document_as && this.infos_contract[0].contract_document_as.contractdocument_id) {
					this.document_as = new File([], this.infos_contract[0].contract_document_as.contractdocument_filename)
					this.document_as_ca = this.infos_contract[0].contract_document_as.contractdocument_ca == 1
				}

				if(this.contract.seasonmarestallion) {
					this.seasonmarestallion_status = Common.translateSeasonMareStatus(this.contract.seasonmarestallion.seasonmarestallion_status)
					this.contract.seasonmarestallion.customClasses={
						seasonmarestallion_status:
						(this.seasonmarestallion_status.indexOf('DG+')!=-1 || this.seasonmarestallion_status.indexOf('PD+')!=-1)?"green":
						(this.seasonmarestallion_status.indexOf('DG-')!=-1 || this.seasonmarestallion_status.indexOf('PD-')!=-1)?"orange":""
					}
				}

				let trackings_mail = this.contract.trackings.filter(track => track.contracttracking_type == 'mail')
				let trackings_signature = this.contract.trackings.filter(track => track.contracttracking_type == 'signature')

				if(trackings_mail.length > 0) {
					this.tracking = this.formatDate(trackings_mail[trackings_mail.length - 1].contracttracking_date)
				}

				if(trackings_signature.length > 0) {
					let tracking = trackings_signature[trackings_signature.length - 1]
					if(tracking) {
						this.tracking_signature = {
							date: this.formatDate(tracking.contracttracking_date),
							user: tracking.contracttracking_data
						}
					}
				}

				if(this.contract.contract_config.contractconfig_horse) {
					this.contract_config.contractconfig_horse = this.contract.contract_config.contractconfig_horse
				}
			},
			async initMareStatus() {
				this.mare_status = await this.loadMareStatus()
			},
			async initTiers() {
				this.loadingTiers = true
				this.tiers = await this.loadTiers()
				this.tiers_courtier = this.tiers
				this.all_tiers = this.tiers
				this.loadingTiers = false

				let preselection_tiers = []
				if(this.contract.tiers)
				{
					this.form.tiers = this.contract.tiers
				}
				else
				{
					if(this.avenant.avenant_horse != null)
					{
						//Allez chercher dans tiers_horse le 1er que je trouve
						// preselection_tiers = await this.loadHorseTiers(this.avenant.avenant_horse)
						// if(preselection_tiers.length > 0)
						// {
						// 	this.form.tiers = preselection_tiers[0]
						// }
						this.form.tiers = await this.getPrincipalTiers(this.avenant.avenant_horse)
					}
					else if (this.$route.params.tier_id && this.$route.params.tier_id != 0)
					{
						this.form.tiers = this.tiers.find(element => element.tiers_id == this.$route.params.tier_id);
					}
				}

				//Je vais restreindre mes tiers si je viens de la fiche jument
				if(this.$route.params.from == "mareList")
				{
					this.tiers_options = this.tiers.filter((el) => {
						return preselection_tiers.some((f) => {
							return f.tiers_id === el.tiers_id ;
						});
					});
				}

				if(this.from == "tiersList") {
					const t = await this.loadHorse(this.form.tiers.tiers_id)
					const tiershorse_juments = t.filter(th => {
						return th.horse.horse_sexe == 'F'
					})

					this.juments_options = tiershorse_juments.map(th => {
						return th.horse
					})
				}
			},
			async initJuments() {
				this.loadingJument = true
				this.juments = await this.loadJuments()
				this.all_juments = this.juments
				let jumentInfo = {}
				if (this.juments[0] && this.form.jument.length > 0){
					this.jument = await this.getHorseById(this.juments[0].horse_id)
					if(this.jument.horse_mort === 1) {
						this.disableSave = true
					}
					else {
						this.disableSave = false
					}

					if(this.form.jument.length > 0){
						jumentInfo = {"sire": this.jument.horse_sire + this.jument.horse_cle, "pere": this.jument.pedigree.pere}
					}
				} else if(this.$route.params.mare_id && this.$route.params.mare_id != 0) {
					this.form.jument = this.juments.find(element => element.horse_id == this.$route.params.mare_id);

					if(this.form.jument && this.form.jument.length > 0){
						jumentInfo = {"sire": this.jument.horse_sire + this.jument.horse_cle, "pere": this.jument.pedigree.pere}
					}
				}

				if(jumentInfo && jumentInfo.sire == 0) {
					jumentInfo.sire = ''
				}
				if(Object.prototype.hasOwnProperty.call(jumentInfo, 'sire')) {
					this.jumentInfo = jumentInfo
				}

				this.loadingJument = false
			},
			async initForm() {
				//Si l'avenant est encore ou a été remis en brouillon/dévalidé
				if(this.avenant.status.avenantstatus_id == 1)
				{
					this.isDisabled = false
				}
				else {
					this.isDisabled = true
				}

				if(this.contract.courtier != undefined)
				{
					this.form.courtier = this.contract.courtier
				}

				this.form.contract_note = this.contract.contract_note
				this.form.avenant_justification = this.avenant.avenant_justification
				this.form.avenant_comment = this.avenant.avenant_comment
				this.form.avenant_transfert = this.avenant.avenant_transfert
				this.form.avenant_free = this.avenant.avenant_free
				this.form.paillette = this.contract.contract_paillette
				this.form.contract_created = new Date(this.contract.contract_created)

				this.note = this.contract.contract_note
				this.comment = this.avenant.avenant_comment
				this.justification = this.avenant.avenant_justification

				if(this.contract.tiers)
				{
					this.tiers = [this.contract.tiers]
					this.form.tiers = this.contract.tiers
				}

				if(this.avenant.horse != undefined)
				{
					this.form.jument = this.avenant.horse
					this.juments = [this.form.jument]
				}

				if(this.avenant.avenant_porteuse != undefined)
				{
					this.form.porteuse = this.avenant.porteuse
					this.juments_porteuse = [this.form.jument]
				}

				if(this.avenant.cmep != undefined)
				{
					this.form.cmep = this.avenant.cmep
				}

				let nb_avenants = await this.getAllAvenantFromContract(this.contract_id)
				if(nb_avenants.length > 1)
				{
					this.has_many_avenant = true
				}
			},
			async initSeason() {
				this.all_season = await this.loadSeasons(true)
			},
			async SetInfoJument(val){
				if (this.NewjumentLoaded) {
					this.NewjumentLoaded = false
					return
				}
				if(val.horse_id){
					this.jument = await this.getHorseById(val.horse_id)
					this.form.jument = this.jument
					this.NewjumentLoaded = true
					let jumentInfo = {"sire": this.jument.horse_sire + this.jument.horse_cle, "pere": this.jument.pedigree.pere}
					if(jumentInfo && jumentInfo.sire == 0) {
						jumentInfo.sire = ''
					}
					
					this.jumentInfo = jumentInfo
				}
			},
			async checkContract0(validation = false){
				this.saveAndValidate = validation
				
				let tab_currency = []
				if(this.avenant_article.length > 0) {
					for (let i = 0; i < this.avenant_article.length; i++) {
						const element = this.avenant_article[i];
						if(element.author.tiers_currency) {
							tab_currency.push(element.author.tiers_currency)
						}
					}
				}
				tab_currency = _uniq(tab_currency)
				if(tab_currency.length == 1) {
					this.actual_price_and_currency = await this.priceFormat(0, tab_currency[0], true)
				} else {
					this.actual_price_and_currency = await this.priceFormat(0, 'EUR', true)
				}

				let total_contract = 0
				for(const article of this.avenant_article){
					total_contract += parseInt(article.avenantarticles_ht)
				}

				if(total_contract == 0 && this.avenant.avenant_free != 1){
					this.$refs['modalConfirm0'].show()
					return 
				}

				await this.saveInfo(this.saveAndValidate)	
			},
			continueContract0(){
				this.$refs['modalConfirm0'].hide()
				this.saveInfo(this.saveAndValidate)
			},
			cancelContract0(){
				this.$refs['modalConfirm0'].hide()
			},
			async saveInfo(validation = false){

				if(this.processing == false)
				{
					this.processing = true

					this.errors = []
					this.form_message = ''

					if(this.contractconfig_id == 0)
					{
						this.errors.push("season")
						this.errors.push("stallion")
						this.form_message = "monte.contract_config_not_found"
					}
					else if (!this.type_monte) {
						this.errors.push('type_monte')
						this.form_message = 'monte.type_monte_not_set'
					}
					else if (!this.modele) {
						this.errors.push('modele')
						this.form_message = 'monte.modele_not_set'
					}

					//Je vais regarder qu'on a une seule devise dans les articles à facturer 
					let tab_currency = []
					if(this.avenant_article.length > 0) {
						for (let i = 0; i < this.avenant_article.length; i++) {
							const element = this.avenant_article[i];
							if(element.author.tiers_currency) {
								tab_currency.push(element.author.tiers_currency)
							}
						}
					}
					tab_currency = _uniq(tab_currency)
					if(tab_currency.length > 1) {
						this.errors.push('currency')
						this.form_message = 'monte.multiple_currency'
					}

					if (this.form_message) {
						this.processing = false
						return
					}

					if(!this.form.avenant_transfert) {
						this.form.porteuse = null
					}

					if(!this.avenant.avenant_dn_bool && this.avenant.avenant_dn) {
						this.avenant.avenant_dn = null
					}

					let jument_id = this.form.jument.horse_id
					if(jument_id < 0) {
						await this.$sync.force(false, false) 
						jument_id = this.$sync.replaceWithReplicated('horse', this.form.jument.horse_id)
					}
					let porteuse_id = null
					if(this.form.porteuse) {
						porteuse_id = this.form.porteuse.horse_id
						if(porteuse_id < 0) {
							await this.$sync.force(false, false) 
							porteuse_id = this.$sync.replaceWithReplicated('horse', this.form.porteuse.horse_id)
						}
					}

					let infos = {
						contract_note: this.form.contract_note,
						contract_created: this.form.contract_created ? this.form.contract_created : new Date(),
						avenant_justification: this.form.avenant_justification,
						avenant_comment: this.form.avenant_comment,
						avenant_transfert: this.form.avenant_transfert,
						avenant_free: this.avenant.avenant_free,
						avenant_dps: this.num_dps,
						avenant_as_bool: this.avenant.avenant_as_bool,
						avenant_dn_bool: this.avenant.avenant_dn_bool,
						avenant_revue_bool: this.avenant.avenant_revue_bool,
						avenant_dn: this.avenant.avenant_dn ? Date.parseTz(this.avenant.avenant_dn) : null,
						avenant_type: 1,
						cmep: this.form.cmep,
						type_monte: this.type_monte,
						modele: this.modele,
						courtier: this.form.courtier,
						jument_id: jument_id,
						porteuse_id: porteuse_id,
						paillette: this.form.paillette,
					}

					if(!this.isDisabled) {
						await this.saveTiersContract(this.contract_id, this.form.tiers.tiers_id)
					}

					//await this.checkHorseTiers(this.form.tiers.tiers_id, this.form.jument.horse_id)

					let res = await this.saveContractOnline(this.contract.contract_id, infos, this.contractconfig_id, this.avenant.avenant_id, this.avenant_article, this.isDisabled)
					if(res.alert && res.alert != ""){
						this.infoToast('toast.' + res.alert)
					}
					this.infos_contract[0].articles = res.avenant_articles
					
					if(!this.contract.contract_num) {
						this.contract.contract_num = res.contract_num
					}

					// if(this.contract.contract_id < 0 || this.avenant.avenant_id < 0)
					// {
					// 	await this.$sync.force(true, true)
					// 	this.avenant.avenant_id = this.$sync.replaceWithReplicated('contract_avenant', this.avenant.avenant_id)
					// 	this.contract.contract_id = this.$sync.replaceWithReplicated('contract', this.contract.contract_id)
					// }

					// await this.saveAvenantArticles(this.avenant.avenant_id, this.avenant_article, this.contract.contract_id)
					if(this.saillie != null){
						await this.savePartContract(this.contract.contract_id, this.saillie.saillie_id)
						this.resetSyndic()
					}
					else if(!this.saillie && this.free_saillie) {
						await this.freeSaillieContractv2(this.contract.contract_id)
						this.resetSyndic()
					}

					let horse_id = this.form.jument.horse_id
					if(!this.season_mare && horse_id && this.season) {
                    	await this.addHorseToSeason(horse_id, this.season.season_id)
						this.season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season.season_id)[0]
					}

					// met à jour le season_mare_stallion de la jument sur l'étalon du contrat
					// if(Object.keys(this.form.jument).length > 0 && this.season_mare) {
					// 	await this.editAllSeasonMareStallionDefault(this.season_mare.seasonmare_id, { seasonmarestallion_default: 0 })
					// 	const seasonstallion = await this.getSeasonMareStallionBySeasonMareAndStallion(this.season_mare.seasonmare_id, this.stallion.horse_id)

					// 	const porteuse = this.form.porteuse ? this.form.porteuse.horse_id : null
					// 	let sm_porteuse = null

					// 	if(porteuse) {
					// 		sm_porteuse = await this.loadSeasonMareStallionBySeasonHorse(porteuse, this.season.season_id)
					// 		if(!sm_porteuse) {
					// 			await this.addHorseToSeason(porteuse, this.season.season_id)
					// 			sm_porteuse = await this.loadSeasonMareStallionBySeasonHorse(porteuse, this.season.season_id)
					// 		}
					// 		sm_porteuse = sm_porteuse[0].seasonmare_id
					// 	}

					// 	if(!seasonstallion) {
					// 		await this.addSeasonMareStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, null, 1, sm_porteuse)
					// 	} else {
					// 		await this.editSeasonMareStallion(seasonstallion.seasonmarestallion_id, { seasonmarestallion_default: 1, seasonmarestallion_porteuse: sm_porteuse })
					// 	}
					// }

					await this.saveSeasonMare()
					
					// await this.$sync.force(true, true)

              		// ContractCleaner.inst().onMutation([this.contract_id], ['articles'])

              		if(this.document_dn) {
	              		let document_id = this.infos_contract[0].contract_document_dn ? this.infos_contract[0].contract_document_dn.contractdocument_id : null
	              		let params = {
	              			document_dn: true,
	              			document_ca: this.document_dn_ca,
	              			document_label: this.getTrad('monte.declaration_naissance')
	              		}
						if(document_id) {
							await this.editContractDocument(this.contract_id, document_id, params)
						}
						else {
							const doc = await this.addContractDocument(this.contract_id, params)
							document_id = doc.contractdocument_id
						}

						if(this.document_dn.size > 0)
							await this.uploadDocumentContractFile(this.contract_id, document_id, this.document_dn)
					}

					if(this.document_as) {
	              		let document_id = this.infos_contract[0].contract_document_as ? this.infos_contract[0].contract_document_as.contractdocument_id : null
	              		let params = {
	              			document_as: true,
	              			document_ca: this.document_as_ca,
	              			document_label: this.getTrad('monte.attestation_saillie')
	              		}
						if(document_id) {
							await this.editContractDocument(this.contract_id, document_id, params)
						}
						else {
							const doc = await this.addContractDocument(this.contract_id, params)
							document_id = doc.contractdocument_id
						}

						if(this.document_as.size > 0)
							await this.uploadDocumentContractFile(this.contract_id, document_id, this.document_as)
					}

					this.successToast("toast.info_modif_succes")
					this.processing = false
					this.tableAvenantShow = false // On supprime les lignes suggérées de facturation
					EventBus.$emit('PlanningMonte::refresh')

					if(validation == true) {
						await this.init_component(validation)
					}
				}
			},
			async saveInfoValide() {
				await this.checkContract0(true)
			},
			addAvenant() {
				this.$refs.modalAddAvenant.openModal()
			},
			async quickPreview(doc) {
                this.$refs.modelQuickPreview.show()
                this.preview = doc.contractdocument_s3_url
            },
			signature() {
				if((this.contract.contract_config || this.contract_config) && (this.contract.contract_config.contractconfig_id || this.contract_config.contractconfig_id)
					&& this.season.season_id) {
					this.$refs.modalContractSignature.openModal()
				}
				else {
					this.failureToast('monte.unable_open')
				}
			},
			async quickContractPreview(contract_id) {
                this.$refs.modelPreview.show()
                let retour = await this.printContract(contract_id, true)
                if(retour) {
                    this.base64 = retour
                }
            },
			async deleteDocument(doc) {
				await this.deleteContractDocument(this.contract_id, doc.contractdocument_id)
				if (doc.contractdocument_dn == 1){
					this.document_dn = null
					this.infos_contract[0].contract_document_dn = {
						'contractdocument_id': null,
						'contractdocument_ca': false,
						'contractdocument_filename':''
					}
				}else{
					this.document_as = null
					this.infos_contract[0].contract_document_as = {
						'contractdocument_id': null,
						'contractdocument_ca': false,
						'contractdocument_filename':''
					}
				}
				this.nb_documents = this.nb_documents - 1
			},
			etatFacturation(){
				this.show_bons = !this.show_bons
			},
			async callPrintContracts(contract_id){
				let id = this.$sync.replaceWithReplicated('contract', contract_id)
				if(this.processing){
					this.failureToast("monte.wait_sync")
					return false
				}

				this.spinning_print = true
				let result = await this.printContract(parseInt(id))
				if(result == null){
					this.failureToast('monte.print_error')
				}
				this.spinning_print = false
			},
			async callSendContracts(contract_id){
				let id = this.$sync.replaceWithReplicated('contract', contract_id)
				if(this.processing){
					this.failureToast("monte.wait_sync")
					return false
				}

				this.spinning_send = true
				this.openModalSendContract([id])
				this.spinning_send = false
			},
			async callCancelContracts(contract_id){
				this.isAvenantCancel = true
				await this.addAvenant()
			},
			async callUndoCancelContracts(contract_id){
				this.addOrEditReady = false

				let infos = {}
				await this.createAvenantOnline(
					this.avenant.avenant_id,
					'desannulation',
					contract_id,
					this.contractconfig_id,
					infos,
					new Date(this.avenant.avenant_date),
					this.avenant.avenant_transfert
				)

				// await this.UndoCancelContract(contract_id, infos)
				this.init_component()
			},
			async callUnsignContracts(avenant_id){
				await this.unsignContract(avenant_id)
				EventBus.$emit('TableAvenantArticle::ReloadArticle', true)
				await this.init_component()
				this.$refs.TypeMonte.loadTypeMonte(true)
			},
			updateEditorNote(val){
				this.form.contract_note = val.val
			},
			updateEditorComment(val){
				this.form.avenant_comment = val.val
			},
			updateEditorJustification(val){
				this.form.avenant_justification = val.val
			},
			async selectStatus() {
				let horse_id = this.form.jument.horse_id
				if (!horse_id || !this.season || !this.season.season_id) {
					return
				}

				const season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season.season_id)
				this.season_mare = season_mare[0]
				if(this.season_mare) {
					this.mare_status_selected = this.mare_status.find(stat => stat.marestatus_id == this.season_mare.seasonmare_status);
				} else {
                    await this.addHorseToSeason(this.form.jument.horse_id, this.season.season_id)
                    this.selectStatus()
				}
			},

			async reloadJument(tiers) {
				const horse_tiers = await this.loadHorse(tiers.tiers_id);
				this.juments = horse_tiers.map(horse => horse.horse).filter(horse => horse != undefined && horse.horse_sexe == "F")
				this.juments_porteuse = this.juments
			},

			async reloadNumDps() {
				if(!this.avenant.avenant_dps && this.form.jument && this.stallion && this.season) {
					if(this.form.jument.horse_id && this.stallion.horse_id && this.season.season_id) {
						this.num_dps = await this.getNumDps(this.form.jument.horse_id, this.stallion.horse_id, this.season.season_id)
					}
				}
			},

			async loadSyndic(force=false) {
				if(this.stallion && this.stallion.horse_id && this.season && this.season.season_id) {
					if((this.syndic_horse.stallion != this.stallion.horse_id) || (this.syndic_horse.season != this.season.season_id) || force) {
						this.syndic_horse.stallion = this.stallion.horse_id
						this.syndic_horse.season = this.season.season_id

						this.load_syndic = true
						// this.syndic_saillie = await this.loadHorseSyndicBySeason(this.stallion.horse_id, this.season.season_id, this.avenant.avenant_date_validation)
						this.syndic_saillie = this.infos_contract[0].syndic
						this.load_syndic = false
					}
				}
			},

			async checkContract() {
				this.mare_contract = false
				this.couple_contract = false

				if(!this.form.jument.horse_id || !this.season) return false

				const all_contract = await this.loadContracts(this.form.jument.horse_id)
				const contract_season = all_contract.filter((contract) => {
					if(typeof contract.contract !== "object" || contract.contract.length == 0) {
						return false
					}
					return contract.contract.config.contractconfig_season == this.season.season_id
					&& contract.contract.avenant.avenant_status != 4
					&& contract.contract.contract_id != this.$sync.replaceWithReplicated('contract', this.contract_id)
				})
				this.mare_contract = contract_season.length > 0

				if(this.stallion) {
					const contract_couple = all_contract.filter((contract) => {
						if(typeof contract.contract !== "object" || contract.contract.length == 0) {
							return false
						}
						return contract.contract.config.contractconfig_season == this.season.season_id
						&& contract.contract.contract_id != this.$sync.replaceWithReplicated('contract', this.contract_id)
						&& contract.contract.config.horse.horse_id == this.stallion.horse_id
					})
					this.couple_contract = contract_couple.length > 0
				}
			},

			async loadTiersCentreStockage() {
				this.available_centre_stockage = await this.loadCentreStockage()
			},

			openPoulinage() {
				let mare = this.avenant.avenant_horse
				if(this.avenant.avenant_porteuse) {
					mare = this.avenant.avenant_porteuse
				}

				this.setupAddPoulinage(mare)
			},

			openDG() {
				let mare = this.avenant.avenant_horse
				if(this.avenant.avenant_porteuse) {
					mare = this.avenant.avenant_porteuse
				}

				this.setupAddDG(mare)
			},

			openSaillie() {
				let mare = this.avenant.avenant_horse
				if(this.avenant.avenant_porteuse) {
					mare = this.avenant.avenant_porteuse
				}

				this.setupAddSaillie(mare)
			},

			onShuttersSubmit() {
				this.shutterPanel().close('act-add-form')
			},

			onShuttersSubmitPoulinage() {
				this.shutterPanel().close('ajout-acte-poulinage')
			},

			onShuttersSubmitDG() {
				this.shutterPanel().close('ajout-acte-dg')
			},

			onShuttersSubmitSaillie() {
				this.shutterPanel().close('ajout-acte-saillie')
			},

			async addPorteuse() {
				if(!this.season) return false

				let shutter = this.monteShutters['monte-horse-ajout']
				shutter.props.season_id = this.season.season_id
				shutter.props.contract_num = this.contract.contract_num
				shutter.props.unknown = true

				shutter.onOk = async (horse_id) => {
					this.juments_porteuse = await this.getHorseFemelle()
					this.form.porteuse = this.juments_porteuse.find(horse => horse.horse_id == horse_id)
				}

				this.shutterPanel().open(shutter)
			},
			async saveSeasonMare() {
				const horse_id = this.form.jument.horse_id

				if(!this.season.season_id || !horse_id || !this.stallion || !this.stallion.horse_id) return false

				// si j'ai pas de season_mare je le crée
				let season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season.season_id)
				this.season_mare = season_mare[0]
				if(!this.season_mare) {
                    await this.addHorseToSeason(horse_id, this.season.season_id)
					let season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season.season_id)
					this.season_mare = season_mare[0]
				}

				if(this.mare_status_selected) {
					await this.saveStatutSanitary(this.season_mare.seasonmare_id, this.mare_status_selected.marestatus_id)
				}

				// je mets tous les sms de la jument en default à 0
				await this.editAllSeasonMareStallionDefault(this.season_mare.seasonmare_id, { seasonmarestallion_default: 0 })

				// si je n'ai pas de porteuse
				if(!this.form.porteuse || !this.form.porteuse.horse_id) {
					// je récupère le sms, si j'en ai un je le passe en default à 1, sinon je le crée 
					const seasonstallion = await this.getSeasonMareStallionBySeasonMareAndStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, true)
					if(!seasonstallion) {
						await this.addSeasonMareStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, null, 1)
					} else {
						await this.editSeasonMareStallion(seasonstallion.seasonmarestallion_id, { seasonmarestallion_default: 1 })
					}
				}
				else {
					const porteuse = this.form.porteuse.horse_id

					// je récupère le season_mare sur la porteuse, sinon je le crée
					let sm_porteuse = await this.loadSeasonMareStallionBySeasonHorse(porteuse, this.season.season_id)
					sm_porteuse = sm_porteuse[0]
					if(!sm_porteuse) {
	                    await this.addHorseToSeason(porteuse, this.season.season_id)
						sm_porteuse = await this.loadSeasonMareStallionBySeasonHorse(porteuse, this.season.season_id)
						sm_porteuse = sm_porteuse[0]
					}

					const seasonstallion = await this.getSeasonMareStallionBySeasonMareStallionAndPorteuse(this.season_mare.seasonmare_id, this.stallion.horse_id, sm_porteuse.seasonmare_id)
					if(!seasonstallion) {
						await this.addSeasonMareStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, null, 1, sm_porteuse.seasonmare_id)
					} else {
						await this.editSeasonMareStallion(seasonstallion.seasonmarestallion_id, { seasonmarestallion_default: 1, seasonmarestallion_porteuse: sm_porteuse.seasonmare_id })
					}
				}
			},

			async contractSigned() {
				this.addOrEditReady = false
				this.$refs.modalContractSignature.hideModal()
				await this.loadSyndic(true)
				this.init_component()
				this.loadEtatFacturation()
			},

			openModalSendContract(contract_ids) {
				this.$refs.modal_send_contract.openModal(contract_ids)
			},

			resetSyndic() {
				this.saillie = null
				this.free_saillie = 0
				this.syndic_saillie = []
			},

			openAddHorseModal(val){
				this.horseToAddName = val
				this.$refs.modalAddHorse.show()
			},

			stopFocus(){
				document.activeElement.blur();
			},
			
			async checkFormHorse() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.tiersHorseAjout.checkForm()
                    if(result) {
                        this.$refs.modalAddHorse.hide()
						this.processing = false
                    } else {
                        this.processing = false
                    }
                }
            },

			async assignHorse(form){
				await this.SetInfoJument(form)
				await this.$refs.searchJument.onHorseId(form.horse_id)
				await this.$sync.force(true)
			},

			async load_most_used_cmep(tiers_id) {
				const most_used_cmep = await this.getMostUsedCmep(tiers_id)
                if(most_used_cmep) {
				    this.most_used_cmep = most_used_cmep
                }
			},

			loadCmep() {
				this.form.cmep = this.most_used_cmep
			},

			async goToSaveContractVersion() {
				this.processing_action = true
				await this.saveContractVersion(this.contract_id)
            	this.successToast("toast.info_save_succes")
				this.processing_action = false
			},
			async loadDPSinfi() {
				this.processing_dps = true
                let dps = await this.getInfoSailliesWs(this.avenant.avenant_horse, this.season.season_id)

                if(dps.length > 0) {
                	let dps_contract = dps.find(d => d.etalonNom == this.contract.contract_config.horse.horse_nom)
                	if(dps_contract) {
                		this.num_dps = dps_contract.monteSaillie
                		// TODO clé
                	}
                }

                this.processing_dps = false
			},
			async loadPreviousDPSinfo() {
				this.processing_previous_dps = true
				const previous_season = await this.getPreviousSeasonFromSeason(this.season.season_id)
				if(!previous_season) return null

                this.previousDPS = await this.getInfoSailliesWs(this.form.jument.horse_id, previous_season.season_id)

                if(this.previousDPS.length > 0) {
					this.actes_types = await this.getActeTypeSaillie()
					this.previousDPS.forEach((dps) => {
						dps.selected = true
                    	dps.acte_type = this.actes_types[0]
					})
					// Ouvrir le popup avec les infos
					this.$refs.modalPreviousDPS.show()
				}
				else{this.failureToast('gynecologie.aucune_donnee_dps_saillie_ws')}

                this.processing_previous_dps = false
			},
			async add_dps() {
                this.loading_add_dps = true;

                const checked = this.previousDPS
                    .filter(_dps => _dps.selected);

                let data = []
                for(let i = 0; i < checked.length; i++) {
                    data.push({
                        monteSaillie: checked[i].monteSaillie,
                        monteAnnee: checked[i].monteAnnee,
                        monteActeType: checked[i].acte_type.actestype_id
                    })
                }
                
                if(data) {
                    this.error_dps = false
                    const result = await this.AddInfoSailliesWs(this.form.jument.horse_id, data)
                    if(result) {
                        if(result.retour.erreur == false) {
                            this.successToast()
                            this.$refs['modalPreviousDPS'].hide()
                        } else {
                            this.error_dps = true
                        }
                    }
                }

                this.loading_add_dps = false;
            },
			shareDocumentAS() {
				this.setupShareDocument(this.contract_id, 'AS')
			},
			shareDocumentDN() {
				this.setupShareDocument(this.contract_id, 'DN')
			},
			shareDocumentsDNAndAS() {
				this.setupShareDocument(this.contract_id, 'DN_AS')
			},

			async loadMareTiers() {
				if(this.form.jument && this.form.jument.horse_id && this.form.tiers && this.form.tiers.tiers_id) {
					const all_tiers = await this.loadTiersPart(this.form.jument.horse_id)
					const tiers = all_tiers.tiers_horse.filter(tier => tier.tiers.tiers_id == this.form.tiers.tiers_id)
					if(tiers.length == 0) {
						this.tiers_contract_error = true
						return false
					}
					const tier = tiers[tiers.length-1]
					if(tier && tier.tiers_horse_part && tier.tiers_horse_part.length > 0 && tier.tiers_horse_part[tier.tiers_horse_part.length-1].tiershorsepart_contract !== 0) {
						this.tiers_contract_error = false
					} else {
						this.tiers_contract_error = true
					}
				}
			},

			async changeStallion(val) {
				await this.getContractConfigInfos(val)
				.then((res) => {
					this.nb_vs = res.count.nb_vs
					this.nb_p = res.count.nb_p
					this.stallion_models = res.models
					this.type_montes_stallion = res.type_monte
					this.default_cmep = res.default_cmep
				})
			},

			async loadSeasonConditions() {
				this.cc_global = await this.loadConditionsSeasonContractConfig(this.season.season_id)
				if(this.cc_global && this.cc_global.contractconfigglobal_paillette && this.cc_global.contractconfigglobal_paillette !== 0
					&& (!this.contract.contract_paillette || this.contract.contract_paillette == 0)) {
					this.form.paillette = this.cc_global.contractconfigglobal_paillette
				}
			},

			loadEtatFacturation() {
				this.setupContractAValiderV2(this.contract_id, this.form.jument.horse_id, this.processing)
			},

			openModalSignature() {
				this.$refs.modal_signature_contract.openModal()
			},

			updateDPS() {
				this.show_dps = false
				this.$nextTick(() => {
					this.show_dps = true
				})
			}
		},
		computed: {
			date_signature() {
				return this.formatDate(this.avenant.avenant_date_signature)
			},
			canTakeResaMonte() {
				const reservable_types = ['mm', 'iaf']

				return this.contract
					&& this.contract.contract_num
					&& this.type_monte
					&& reservable_types.indexOf(this.type_monte.type) == -1
			},
			syndic_saillie_formatted() {
				if(_isEmpty(this.syndic_saillie)) return []

				let type_contrat = "syndicpart_is_perso"
				if(this.avenant.avenant_type_contrat == "sur_pool") {
					type_contrat = "syndicpart_is_pool"
				}

				let res = []
				this.syndic_saillie.forEach(syndic => {
					syndic.syndic_saillies.forEach(saillie => {
						if(saillie.saillie_part[type_contrat] == 1) {
							if(saillie.saillie_part.syndicpart_tiers.length === 0 || (saillie.saillie_contract !== null && saillie.saillie_contract !== this.contract_id)) {
								return
							}
							
							let tiers_tab = []
							saillie.saillie_part.syndicpart_tiers.forEach(tiers => {
								tiers_tab.push(tiers.tierpart_tier.tiers_rs)
							})

							const tab_current = {
								value: `${syndic.syndic_label} # ${saillie.saillie_number} / ${tiers_tab.join(' - ')} / ${this.$t(saillie.saillie_part.syndicpart_invoice_type.invoicetype_labeltrad)}`,
								invoice_type: saillie.saillie_part.syndicpart_invoice_type.invoicetype_code,
								saillie_id: saillie.saillie_id,
								type_contrat: type_contrat,
								syndicat: this.syndic_saillie.syndic_tiersyndicat,
								tiers: {
										tiers_id:saillie.saillie_part.syndicpart_tiers[0].tierpart_tier.tiers_id,
										tiers_rs:saillie.saillie_part.syndicpart_tiers[0].tierpart_tier.tiers_rs
									}
							}
							res.push(tab_current)

							if(saillie.saillie_contract == this.contract_id) {
								this.has_part = true
								this.saillie = tab_current
							}
						}
					})
				})

				return res
			},
			hasClientArea() {
				return this.$store.state.userAccess.hasDedicatedClientArea
			},
			hasSignatureAccess() {
				return this.$store.state.userAccess.hasSignatureAccess
			},
		},
		watch: {
			'form.jument' (val) {
				if(val) {
					this.selectStatus()
					this.reloadNumDps()
					this.checkContract()
					this.SetInfoJument(val)
					this.initJuments()
					this.loadMareTiers()
				}
			},

			'season' () {
				this.selectStatus()
				this.reloadNumDps()
				this.checkContract()
				this.loadSeasonConditions()
				if(this.contract.contract_num){
					this.loadSyndic()
				}
			},

			'form.tiers' (val) {
				this.reloadJument(val)
				this.load_most_used_cmep(val.tiers_id)
				this.loadMareTiers()
			},
			stallion(val) {
				if(this.contract.contract_num){
					this.loadSyndic()
				}
				this.reloadNumDps()
				this.checkContract()
			},
			canTakeResaMonte(val) {
				if(val) this.loadTiersCentreStockage()
			},
			contract_config(val) {
				if((!this.contract.contract_paillette || this.contract.contract_paillette == 0) && val.contractconfig_paillette !== 0) {
					this.form.paillette = val.contractconfig_paillette
				}
				// if((!this.form.cmep || this.form.cmep == 0) && !this.avenant) {
				// 	this.form.cmep = this.all_tiers.find(tiers => tiers.tiers_id == val.contractconfig_default_cmep)
				// }
			},
			free_saillie(val) {
				if(val) {
					this.saillie = null
				}
			},
			async type_monte(val) {
				const disabled_types = ['mm', 'mn', 'iaf']
				if((!this.isDisabled && val && disabled_types.includes(val.type)) && this.default_cmep) {
					this.form.cmep = this.all_tiers.find(tiers => tiers.tiers_id == this.default_cmep )
					this.disabledCmep = true
				}
				else if((!this.isDisabled && val && disabled_types.includes(val.type)) && this.cc_global && this.cc_global.contractconfigglobal_default_cmep) {
					this.form.cmep = this.all_tiers.find(tiers => tiers.tiers_id == this.cc_global.contractconfigglobal_default_cmep)
					this.disabledCmep = true
				}
				else {
					this.disabledCmep = false
				}

				if(val) {
					this.cc_articles = val.config_conditions
					if((!this.contract.contract_paillette || this.contract.contract_paillette == 0) && val.paillette !== 0 && val.paillette !== null) {
						this.form.paillette = val.paillette
					}
				}
			},
			contractconfig_id(val) {
				this.changeStallion(val)
			}
		},
		components: {
			HorseAjout,
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			ModalAddAvenantv2 : () => import('@/components/Contract/ModalAddAvenantv2'),
			ModalSignaturev2 : () => import('@/components/Contract/ModalSignaturev2'),
			ChangementEtalon : () => import('@/components/Contract/AvenantType/ChangementEtalon'),
			ChangementCMEPv2 : () => import('@/components/Contract/AvenantType/ChangementCMEPv2'),
			ReservationMonte : () => import('@/components/Contract/Reservation/ReservationList'),
			Saison : () => import('@/components/Contract/Type/Saison'),
			Etalonv2 : () => import('@/components/Contract/Type/Etalonv2'),
			Modelev2 : () => import('@/components/Contract/Type/Modelev2'),
			TypeMontev2 : () => import('@/components/Contract/Type/TypeMontev2'),
			TableAvenantArticlev2 : () => import('@/components/Contract/Type/TableAvenantArticlev2'),
			Bons : () => import('@/components/Invoice/Bons'),
			AValider : () => import('@/components/Contract/AValider'),		
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
			SearchJument : () => import('@/components/Contract/SearchJument'),
			ModalSendContract: () => import('@/components/Contract/ModalSendContract'),
            TagsManagement: () => import('@/components/TagsManagement'),
            ModalSignatureContract: () => import('@/components/Contract/ModalSignatureContract'),
			WarnAlert : () => import('GroomyRoot/components/Alert/WarnAlert'),
		}
	}
</script>

<style scoped>
	.orange {
	background: darkorange;
	color: white;
	padding: 5px;
	}

	.green {
	background: limegreen;
	color: white;
	padding: 5px;
	} 
</style>